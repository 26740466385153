import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-rental-login',
  templateUrl: './rental-login.component.html',
  styleUrls: ['./rental-login.component.scss']
})
export class RentalLoginComponent implements OnInit {
  loginForm: FormGroup;
  loginButtonDisable=false
  submitted = false;
  private clickTimeout :any =null;
  public showPassword: boolean;
  logo: string;
  regButon:boolean=true;
  url_youtube:any;
  url_insta:any;
  url_telegram:any;
  
  constructor(private router: Router,
    private fb: FormBuilder,private toastr: ToastrService,private socket: Socket) { }

  ngOnInit(): void {
    this.changeIcon();
    this.createFrom();
  }

  async findHostName()
    { 
      return (window.location.hostname);
    }

  async changeIcon() {
     const hostname=await this.findHostName();
     const splithostname= hostname.split('.')
     this.logo = splithostname[0];
     this.setManager();
     this.checkShowReg();
  }

  createFrom() {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
      manager: ''
    })
  }

  setManager(){
    if(this.logo==='clubprt')
    {
      this.loginForm.patchValue({manager: 'PRTCLUB'});
      this.url_youtube="";
    this.url_insta="https://instagram.com/prtglobal?igshid=MmIzYWVlNDQ5Yg==";
    this.url_telegram="https://t.me/clubprt";
    }else if(this.logo==='ferrariclubb'){
      this.loginForm.patchValue({manager: 'FERRARICLUB'});
    }
    else if(this.logo==='clubaob'){
      this.loginForm.patchValue({manager: 'AOBCLUB'});
    }
    else if(this.logo==='dlexch'){
      this.loginForm.patchValue({manager: 'DLCLUB'});
    }
    else if(this.logo==='boomboombook'){
      this.loginForm.patchValue({manager: 'BOOM'});
    }
    else if(this.logo==='osgexch365'){
      this.loginForm.patchValue({manager: 'OSG365'});
    }
    else if(this.logo==='fairbets247'){
      this.loginForm.patchValue({manager: 'FAIRBET'});
    }
    else if(this.logo==='telegrambook'){
      this.loginForm.patchValue({manager: 'TELE'});
    }
    else if(this.logo==='777exch'){
      this.loginForm.patchValue({manager: 'EXCH'});
    }
    else if(this.logo==='paisaexch'){
      this.loginForm.patchValue({manager: ''});
    }
    else if(this.logo==='clubosg'||this.logo==='dubaiclub247'){
      this.loginForm.patchValue({manager: 'OSGCLUB'});
      if(this.logo === "clubosg"){
        this.url_youtube="https://youtube.com/@clubosg?si=seaAPXhS_KifDCyu";
        this.url_insta="https://instagram.com/clubosg_official?igshid=OGQ5ZDc2ODk2ZA==";
        this.url_telegram="https://t.me/osgclub";
      }
    }
    else if(this.logo==='clublucky7')
    {
      this.loginForm.patchValue({manager: 'PRTLUCKY'});
    }
    else{
      this.loginForm.patchValue({manager: 'EXCH'});
    }
  }

  checkShowReg(){
    if(this.logo==='Betx' || this.logo==='paisaexch')
    this.regButon=false;
    else{
      this.regButon=true;
    }
  }


  get f() { return this.loginForm.controls; }

 async onSignInClick() {
  this.loginButtonDisable=true;
  if (this.clickTimeout) {
    this.setClickTimeout(() => {});
  } else {
  // if timeout doesn't exist, we know it's first click 
  // treat as single click until further notice
    this.setClickTimeout(() =>  
       this.handleSingleLoginClick());
  }
 }
 public handleSingleLoginClick() {
  //The actual action that should be performed on click      
  this.submitted = true;
  if (this.loginForm.invalid) {
    this.loginButtonDisable=false;
    return;
  }

  const loginData={user:this.loginForm.value}
console.log(loginData);
  this.socket.emit('login',loginData);

  this.socket.on('login-error',(function(o:any){
    this.toastFunction(o.message);
    this.loginButtonDisable=false;
  }).bind(this));
  this.socket.on('get-login-status',(function(error:any){
    // Unable to log in
    this.toastFunction(error.message);
    this.loginButtonDisable=false;
  }).bind(this));
  this.socket.on('login-success',(function(data:any){

    const output = data.output;
    this.toastr.success(data.message, "Login Success!");
   // console.log(output);
    
    
    if(output){
      // Todo: validate output 
      this.loginButtonDisable=false;
      sessionStorage.setItem('loginStatus','true');
      sessionStorage.setItem('userDetails',JSON.stringify(output));
      if(output.details.manager=="OSG365"){
        this.redirectToWallet();
      }else{
        this.redirectToInplay();
      }
      
    }
    
  }).bind(this));
  
}

async redirectToWallet()
{
  var acctoken =  await sessionStorage.getItem( 'loginStatus' );

 if(acctoken != null && acctoken != undefined && acctoken != '')
 {
  this.router.navigate(['/wallet']);
 }
}

 async redirectToInplay()
  {
    var acctoken =  await sessionStorage.getItem( 'loginStatus' );

   if(acctoken != null && acctoken != undefined && acctoken != '')
   {
    this.router.navigate(['dashboard']);
   }
  }

  
  // sets the click timeout and takes a callback 
// for what operations you want to complete when
// the click timeout completes
public setClickTimeout(callback:any) {
  // clear any existing timeout
  clearTimeout(this.clickTimeout);
  this.clickTimeout = setTimeout(() => {
    this.clickTimeout = null;
    callback();
  }, 400);
}


downloadApk(){
  if(this.logo==='clubaob')
     window.open('https://bit.ly/3uXxx38', "_blank");
    else if(this.logo==='clubprt'){
      window.open('https://bit.ly/3PlW8H7', "_blank");
    }
    else if(this.logo==='clubosg'){
      window.open('https://bit.ly/3B0AtzK', "_blank");
    }
    else if(this.logo==='ferrariclubb'){
      window.open('https://bit.ly/3aVxM8j', "_blank");
    }
    else if(this.logo==='betx'){
      window.open('https://bit.ly/3OmN2Zf', "_blank");
    }
    else if(this.logo==='boomboombook'){
      window.open('https://bit.ly/44gLmJo', "_blank");
    }
    else if(this.logo==='777exch'){
      window.open('https://bit.ly/4dNfvVH', "_blank");
    }
    else if(this.logo==='dlexch'){
      window.open('https://bit.ly/3cCLnSe', "_blank");
    }
    else if(this.logo==='fairbets247'){
      window.open('https://bit.ly/3Ru5p0j', "_blank");
    }
    else if(this.logo==='paisaexch'||this.logo==='clubosg'||this.logo==='dubaiclub247')
    {
      window.open('https://bit.ly/3IY4Glj', "_blank");
    }
    else if(this.logo==='clublucky7')
    {
      window.open('https://bit.ly/3IhBLJD', "_blank");
    }
   
}

 toastFunction(msg:any) {
  this.toastr.error(msg+' !');
}

}
